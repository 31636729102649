import vue from "@/main";
import axios from "axios";
import { request, download } from "./axios";
import FileSaver from "file-saver";
import store from "./store";
import router from "./router";
import Element from "element-ui";
import * as ExcelJS from "exceljs/dist/exceljs";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import XLSX from "xlsx";
import heic2any from "heic2any";

export default {
  getToday() {
    var toDay = new Date();
    var year = toDay.getFullYear();
    var month = toDay.getMonth() + 1;
    month = month < 10 ? "0" + month : month;
    var day = toDay.getDate();
    day = day < 10 ? "0" + day : day;
    return year + "-" + month + "-" + day;
  },
  getHours() {
    var date = new Date();
    var hour = date.getHours();
    var minute = date.getMinutes();
    var second = date.getSeconds();
    hour = hour < 10 ? "0" + hour : hour;
    minute = minute < 10 ? "0" + minute : minute;
    second = second < 10 ? "0" + second : second;
    return hour + ":" + minute + ":" + second;
  },
  getMonth() {
    var toDay = new Date();
    var year = toDay.getFullYear();
    var month = toDay.getMonth() + 1;
    month = month < 10 ? "0" + month : month;
    return year + "-" + month;
  },
  iter: (scope, key, postfix = "") => {
    if (typeof key === "number" || typeof key === "string") {
      if (typeof scope === "object") {
        if (scope instanceof Array) {
          const values = scope.filter((e) => key == e.id).map((e) => e.cotent);
          if (postfix) values.push(postfix);
          return values.join("，") || "无";
        } else {
          const values = [];
          for (const k in scope) if (k == key) values.push(scope[k]);
          if (postfix) values.push(postfix);
          return values.join("，") || "无";
        }
      } else {
        return "ERROR";
      }
    } else {
      if (key instanceof Array) {
        if (typeof scope === "object") {
          if (scope instanceof Array) {
            const values = scope
              .filter((e) => key.includes(e.id))
              .map((e) => e.cotent);
            if (postfix) values.push(postfix);
            return values.join("，") || "无";
          } else {
            const values = [];
            for (let k in scope) if (key.includes(k)) values.push(scope[k]);
            if (postfix) values.push(postfix);
            return values.join("，") || "无";
          }
        } else {
          return "ERROR";
        }
      } else {
        return "ERROR";
      }
    }
  },
  iterList: (list, postfix = "") => {
    if (postfix) list = list.concat([postfix]);
    return list.join("，") || "无";
  },
  //下载请求
  async xhrRequestDownload(
    url,
    filename = "Untitled",
    on_progress_chunk = (_progress, _received, _total) => {
      console.log(_progress, _received, _total);
    }
  ) {
    let data = await fetch(url)
      .then(async (response) => {
        const read_stream = async (response) => {
          const total = Number.parseInt(response.headers.get("content-length"));
          on_progress_chunk(0, 0, total);
          const concat_uint8 = (aries) => {
            const length = aries.reduce((v, b) => v + b.length, 0);
            const target = new Uint8Array(length);
            let offset = 0;
            for (const ary of aries) {
              target.set(ary, offset);
              offset += ary.length;
            }
            return target;
          };
          const reader = response.body.getReader();
          let received = 0;
          const data = [];
          while (true) {
            const { done, value } = await reader.read();
            if (!done) {
              data.push(value);
              received += value.length;
              on_progress_chunk(received / total, received, total);
            } else break;
          }
          return data;
        };
        const data = await read_stream(response);
        return data;
      })
      .then((res) => {
        let blod = new Blob([res]);
        let name = filename;
        this.downloadBlob(blod, name);
      });

    return data;
  },
  downloadBlob(url, fileName) {
    try {
      const href = window.URL.createObjectURL(url);
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(url, fileName);
      } else {
        const downloadElement = document.createElement("a");
        downloadElement.href = href;
        downloadElement.target = "_blank";
        downloadElement.download = fileName;
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      }
    } catch (e) {
      alert(e.toString());
    }
  },
  // downloadURL(url, fileName) {
  //     var prefix = url.substring(0, url.indexOf(':'))
  //     var con = url.substring(url.indexOf(':'))
  //     if (prefix == 'http') {
  //         url = 'https' + con
  //     }
  //     try {
  //         const downloadElement = document.createElement("a");
  //         downloadElement.href = url;
  //         downloadElement.target = "_blank";
  //         downloadElement.download = fileName;
  //         downloadElement.setAttribute("download", fileName);
  //         document.body.appendChild(downloadElement);
  //         downloadElement.click();
  //         document.body.removeChild(downloadElement);
  //     } catch (e) {
  //         alert(e.toString());
  //     }
  // },
  downloadURL(url, fileName) {
    if (fileName && !fileName.includes(".")) {
      fileName = fileName + url.substring(url.lastIndexOf("."));
    }
    const downloadElement = document.createElement("a");
    downloadElement.href = `${axios.defaults.baseURL}/downloadFile?fileUrl=${url}&name=${fileName}`;
    document.body.appendChild(downloadElement);
    downloadElement.click();
  },
  //    文件预览
  async handleFilePreview(file) {
    // file.url = encodeURI(file.url)
    if (window.location.protocol.includes("https")) {
      var url = file.url.substring(file.url.indexOf(":"));
      file.url = "https" + url;
    }
    var type = await this.fetchPreviewSystemSetting();
    type = type && type.content ? type.content : 1;
    var index = file.url.lastIndexOf(".");

    var suffix = null;
    var formatArr = ["doc", "docx", "pptx", "xls", "xlsx", "xlsm"];
    var otherFormatArr = [
      "png",
      "jpg",
      "jpeg",
      "webp",
      "gif",
      "txt",
      "mp3",
      "mp4",
      "mpg",
      "mpeg",
      "wmv",
      "mov",
      "rm",
      "ram",
      "pdf",
    ];
    if (file.url) {
      suffix = file.url.substring(index + 1, file.url.length);
    }
    var isFormat = this.otherCompareOneEqualTwo(formatArr.join(","), suffix);
    var isOtherFormat = this.otherCompareOneEqualTwo(
      otherFormatArr.join(","),
      suffix
    );

    if (isFormat) {
      var url = "";
      if (type == 1) {
        url = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
          file.url
        )}`;
      } else if (type == 2) {
        url =
          axios.defaults.baseURL +
          `/Open/onlineOffice?url=${encodeURIComponent(file.url)}`;
      }
      window.open(url, "_blank");
    }
    if (isOtherFormat || (!isFormat && !isOtherFormat)) {
      window.open(file.url, "_blank");
    }
    // if (!isFormat && !isOtherFormat) {
    //     Element.Message({
    //         message: '无法预览，请下载后查看',
    //         type: 'error'
    //     });
    //     this.downloadURL(file.url)
    // }
  },
  otherCompareOneEqualTwo(format, suffix) {
    var reg = new RegExp(suffix, "i");
    return format.match(reg) != null;
  },
  async fetchPreviewSystemSetting() {
    const user = await request.get("/SysUser/getCurrentUser");
    const res = await request.get("/SystemSetting/selectByType", {
      params: { schoolId: user.data.schoolId, type: 5 },
    });
    return res.data;
  },
  //清空表单
  empity(form) {
    for (let key in form) {
      form[key] = null;
    }
    return form;
  },
  //填充表单
  fillWith(target, source) {
    for (const key in target) target[key] = source[key];
  },
  clientWidth() {
    var width = document.body.clientWidth;
    if (width <= 960) {
      return 1;
    } else {
      return 0;
    }
  },
  //    导出excel
  exportExcel(tableOut, fileName) {
    let table = document.getElementById(tableOut);
    var xlsxParam = { raw: true };
    if (!table) return;
    // 复制一份table的dom
    let div = document.createElement("div");
    div.innerHTML = table.innerHTML;
    // 移除掉左右的fixed
    let fixedLeft = div.querySelector(".el-table__fixed");
    if (fixedLeft) fixedLeft.remove();
    let fixedRight = div.querySelector(".el-table__fixed-right");
    if (fixedRight) fixedRight.remove();
    // 导出处理好的数据
    let book = XLSX.utils.table_to_book(div, xlsxParam);
    let write = XLSX.write(book, {
      bookType: "xlsx",
      bookSST: true,
      type: "array",
    });
    try {
      FileSaver.saveAs(
        new Blob([write], { type: "application/octet-stream" }),
        fileName
      );
    } catch (e) {
      console.log("导出失败", e);
    }
  },
  //    查询所有班级
  async fetchAllTeam(periodId, isCheck) {
    const params = {
      periodId: periodId ? periodId : "",
      state: 1,
      isCheck,
    };
    const res = await request.get("/Team/selectAll", { params });
    return res.data;
  },
  //查询学校信息
  async getSchool(schoolId) {
    if (!schoolId) {
      const user = await request.get("/SysUser/getCurrentUser");
      schoolId = user.data.schoolId;
    }

    const res = await request.get("/School/selectById", {
      params: { id: schoolId },
    });
    return res.data;
  },
  //    根据字典值查询
  async fetchDict(type, state, isDesc, isPlatform, schoolInfo) {
    var school = schoolInfo;
    if (isPlatform && !schoolInfo) {
      school = await this.getSchool();
    }
    const params = {
      type,
      state,
      orderName: "id",
      isDesc: isDesc ? isDesc : false,
      platform: school ? school.platform : "",
    };
    const res = await request.get("/Dict/selectAllByCondition", { params });
    return res.data;
  },
  async getCurrentUser() {
    const user = await request.get("/SysUser/getCurrentUser");
    return user.data;
  },
  //查询用户类型
  async getUserType() {
    const user = await request.get("/SysUser/getCurrentUser");
    const res = await request.get("/SysUserInfo/getBySysUserId", {
      params: { sysUserId: user.data.id },
    });
    return res.data;
  },
  async fetchSemesterData(type) {
    const params = {
      type,
      state: 1,
    };
    const res = await request.get("/Dict/selectAllByCondition", { params });
    return res.data;
  },
  //    退出登录
  loginOut(loginType) {
    localStorage.clear();
    sessionStorage.clear();
    store.commit("resetState");
    if (loginType == 1) {
      router.replace({ path: "/loginGate" });
    }
  },
  //    查询关联学校
  async fetchAllSchool() {
    const params = {
      isSameAddress: true,
    };
    const res = await request.get("/School/selectAll", { params });
    return res.data;
  },
  changeDecimalToPercentage(data1, data2, fix, isNeedIcon) {
    if (isNeedIcon == null || isNeedIcon == undefined) {
      isNeedIcon = true;
    }
    var data;
    if (data1 == null || data2 == null || data2 == 0) {
      data = 0;
    } else {
      data = data1 / data2;
    }
    if (isNeedIcon) {
      return (data * 100).toFixed(fix) + "%";
    } else {
      return (data * 100).toFixed(fix);
    }
  },
  exportExcelJs(call, filename) {
    const workbook = new ExcelJS.Workbook();
    call(workbook);
    workbook.xlsx.writeBuffer().then((buffer) => {
      FileSaver.saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        filename
      );
    });
  },
  //    把图片pdf放到一个页面
  download(content, name) {
    var that = this;
    html2canvas(content, {
      allowTaint: true,
      useCORS: true,
      scale: 2, // 提升画面质量，但是会增加文件大小
    }).then(function (canvas) {
      /**jspdf将html转为pdf一页显示不截断，整体思路：
       * 1. 获取DOM
       * 2. 将DOM转换为canvas
       * 3. 获取canvas的宽度、高度（稍微大一点）
       * 4. 将pdf的宽高设置为canvas的宽高
       * 5. 将canvas转为图片
       * 6. 实例化jspdf，将内容图片放在pdf中（因为内容宽高和pdf宽高一样，就只需要一页，也防止内容截断问题）
       */

      // 得到canvas画布的单位是px 像素单位
      var contentWidth = canvas.width;
      var contentHeight = canvas.height;

      // 将canvas转为base64图片
      var pageData = canvas.toDataURL("image/jpeg", 1.0);

      // 设置pdf的尺寸，pdf要使用pt单位 已知 1pt/1px = 0.75   pt = (px/scale)* 0.75
      // 2为上面的scale 缩放了2倍
      var pdfX = ((contentWidth + 10) / 2) * 0.75;
      var pdfY = ((contentHeight + 500) / 2) * 0.75; // 500为底部留白

      // 设置内容图片的尺寸，img是pt单位
      var imgX = pdfX;
      var imgY = (contentHeight / 2) * 0.75; //内容图片这里不需要留白的距离

      // 初始化jspdf 第一个参数方向：默认''时为纵向，第二个参数设置pdf内容图片使用的长度单位为pt，第三个参数为PDF的大小，单位是pt
      var PDF = new jsPDF("", "pt", [pdfX, pdfY]);

      // 将内容图片添加到pdf中，因为内容宽高和pdf宽高一样，就只需要一页，位置就是 0,0
      PDF.addImage(pageData, "jpeg", 0, 0, imgX, imgY);
      // const base64data = PDF.output('datauristring');//PDF转base64

      PDF.save(name);
    });
  },
  //查询本月第一天和最后一天
  fetchStartAndEnd() {
    var nowDate = new Date();
    var cloneNowDate = new Date();
    var fullYear = nowDate.getFullYear();
    var month = nowDate.getMonth() + 1;
    var endOfMonth = new Date(fullYear, month, 0).getDate();

    function getFullDate(targetDate) {
      var D, y, m, d;
      if (targetDate) {
        D = new Date(targetDate);
        y = D.getFullYear();
        m = D.getMonth() + 1;
        d = D.getDate();
      } else {
        y = fullYear;
        m = month;
        d = date;
      }
      m = m > 9 ? m : "0" + m;
      d = d > 9 ? d : "0" + d;
      return y + "-" + m + "-" + d;
    }

    var end = getFullDate(cloneNowDate.setDate(endOfMonth));
    var start = getFullDate(cloneNowDate.setDate(1));
    return [start, end];
  },
  beforeUpload(file) {
    var format = "heic,heif";
    var suffix = file.name.substring(file.name.lastIndexOf(".") + 1);
    var name = file.name.substring(0, file.name.lastIndexOf(".") + 1);
    var reg = new RegExp(suffix, "i");
    var isFormat = format.match(reg) != null;
    if (isFormat) {
      return new Promise((resolve, reject) => {
        heic2any({
          blob: file,
          toType: "image/jpg",
        })
          .then((resultBlob) => {
            var newfile = new File([resultBlob], `${name}.jpg`);
            resolve(newfile);
          })
          .catch((e) => {
            reject(e);
          });
      });
    } else {
      return;
    }
  },

  //使用浏览器导出当前页面pdf
  downloadPdfUseBrowser() {
    const win = window.open(window.location.href, "_self");
    win.print();
  },
};
